import React, { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Form, Button, Modal } from 'antd';
import { Card } from 'antd';
import {authenticationService} from "../../../services/authentication/AuthenticationService";

interface Props {
  modalVisible: boolean;
  onCancelModelDecline: () => void;
  onOkModalDecline: (value) => void;
  onOkModalAccept: () => void;
  loading: boolean;
  data: any;
}

export default function ModalDetail(props: Props) {
  const [reason, setReason] = useState("");
  const [errorReason, setErrorReason] = useState("");

  const onClickOK = () => {
    if (reason.replace(/\s+/g, '') === "") {
      setErrorReason("Lý do từ chối không thể bỏ trống!");
    } else {
      setErrorReason("");
      props.onOkModalDecline(reason);
    }
  };
  const onClickAccept = () => {
    props.onOkModalAccept();
  };

  const renderFooterModal = () => {
    return [
      <Button
        key={1}
        type="primary"
        loading={props.loading}
        onClick={onClickOK}
        hidden={ props.data.status === 3 && authenticationService.currentUserValue.typeUser !== 6 ? false : true}
      >
        Từ chối
      </Button>,
      <Button
      key={2}
      type="primary"
      loading={props.loading}
      onClick={onClickAccept}
      hidden={ props.data.status === 3 && authenticationService.currentUserValue.typeUser !== 6 ? false : true}
    >
      Đã xử lý
    </Button>,
      <Button
        key={3}
        type="default"
        loading={props.loading}
        onClick={props.onCancelModelDecline}
      >
        Cancel
      </Button>
    ];
  };
  return (
    <Modal
      title=""
      visible={props.modalVisible}
      style={{ top: 20 }}
      footer={renderFooterModal()}
    >
      <Card className="m-r-15-i m-l-15-i mt-1" title={<label>Chi tiết yêu cầu hỗ trợ</label>} size="small">
      <Form
        name="basic"
      >
      <div hidden={props.data.status === 1 || props.data.status === 4 ? true : false}>
      <Form.Item
        label="Lý do từ chối"
        validateStatus={errorReason ? "error" : "success"}
        help={errorReason}
        // hidden={true}
      >
        <TextArea
          autoSize ={{ minRows: 3, maxRows: 5 }}
          onChange={e => setReason(e.target.value)}
          value={ props.data.status === 3 ? reason : props.data.reason}
          disabled={ props.data.status === 3 ? false : true}
        />
      </Form.Item>
      </div>
      <div className="input-group">
      <div className="col-md-6">
      <label  className="newca-label undefined">Mã định danh</label>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-control-wrapper">
            <div className="ant-form-item-control has-success">
                <input  type="text" className="ant-input ant-input-disabled" defaultValue={props.data.code} />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6">
      <label  className="newca-label undefined">Tên người dùng</label>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-control-wrapper">
            <div className="ant-form-item-control has-success">
                <input  type="text" className="ant-input ant-input-disabled" defaultValue={props.data.name} />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6">
      <label  className="newca-label undefined">Serial CTS</label>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-control-wrapper">
            <div className="ant-form-item-control has-success">
                <input  type="text" className="ant-input ant-input-disabled" defaultValue={props.data.serial_number} />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6">
      <label  className="newca-label undefined">Mã Token</label>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-control-wrapper">
            <div className="ant-form-item-control has-success">
                <input  type="text" className="ant-input ant-input-disabled" defaultValue={props.data.token_code} />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6">
      <label  className="newca-label undefined">Số điện thoại</label>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-control-wrapper">
            <div className="ant-form-item-control has-success">
                <input  type="text" className="ant-input ant-input-disabled" defaultValue={props.data.phone} />
              </div>
            </div>
        </div>
      </div>
      <div className="col-md-6">
      <label  className="newca-label undefined">Email</label>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-control-wrapper">
            <div className="ant-form-item-control has-success">
                <input  type="text" className="ant-input ant-input-disabled" defaultValue={props.data.email} />
              </div>
            </div>
        </div>
      </div>
      </div>
      </Form>
      </Card>
    </Modal>
  );
}
