import Form, { FormComponentProps } from "antd/lib/form";
import axios from "axios";
import React, { useState } from "react";
import { match } from "react-router";
import ModalEnterPin from "../../components/common/form/ModalEnterPin";
import { loading, loading as loadingHelper } from "../../components/common/loading/Loading";
import { onFailAction, onSuccessAction } from "../../helpers/SwalCommon";
import { SignRevokeDestroyPauseServices } from "../../services/sign-file-revoke-destroy-pause/SignRevokeDestroyPauseServices";

interface Props extends FormComponentProps {
    match: match<{ secret: string}>;
    history: any;
}

const SignRevokeFilePage: React.FC<Props> = props => {
    const [file, setFile] = useState("");
    const [confirm, setConfirm] = useState(false);

    const dataSign = {
        "fieldName":"SignatureB [002]",
        "typeSign":"1",
        "page": "4",
        "px": "86",
        "py": "61",
        "pw": "180",
        "ph": "10"
    };

    const [canSign, setCanSign] = useState(false);
    const secret = props.match.params.secret;

    const getFileSign = async (password) => {
        loadingHelper.runLoadingBlockUI();
        const dataSend = {
            secret: secret,
            password: password
        };
        const result = await SignRevokeDestroyPauseServices.getFileCustomerSign(dataSend);
        if (result && result.status === 200 ){
            setFile(result.data);
            if (result.number_signed !== 2){
                setCanSign(true);
            }
        }
        loadingHelper.stopRunLoading();
        return result;
    };

    const [modalPin, setModalPin] = useState({
        visible_modal_password: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        password: "",
        error_password: "",
    });
    const submitPin = () => {
        setModalPin({...modalPin, 'visible_modal_password': true});
    };
    const confirmPin = async () => {
        if (modalPin.password.trim() === "") {
            setModalPin({...modalPin, 'error_password': "Mã password không được trống"});
            return false;
        }
        const check =  await getFileSign(modalPin.password);
        loading.runLoadingBlockUI();
        if (check.status === 200){
            setConfirm(true);
            await setModalPin({...modalPin, 'visible_modal_password': false });
        }else {
            setConfirm(false);
            onFailAction(check.message);
            await setModalPin({...modalPin, 'visible_modal_password': false });
        }
        loading.stopRunLoading();

    };
    // @ts-ignore
    const onChangeDataPin = ({target: {value}}) => {
        setModalPin({...modalPin, 'password': value});
    };
    const handleModalConnectCancel = () => {
        setModalPin({...modalPin, 'visible_modal_password': false})
    };

    const onDenyClick = async () =>{
        const dataSend = {
            secret: secret,
        };
        const deny = await SignRevokeDestroyPauseServices.denySignRevoke (dataSend);
        if (deny.status === 200){
            onSuccessAction(deny.message);
            window.location.reload();
        }else {
            onFailAction(deny.message);
        }
    };
    const getCertificate = async () => {
        loadingHelper.runLoadingBlockUI();
        const dataSend = {
            secret: secret
        };
        const result = await SignRevokeDestroyPauseServices.getCertificate(dataSend);
        loadingHelper.stopRunLoading();
        return result;
    };
    const onSignClick = async () => {
        let cert = await getCertificate();
        console.log(cert)
        if (!cert.data){
            onFailAction("Không tìm thấy chứng thư số");
            return;
        }
        const time = await SignRevokeDestroyPauseServices.getTimeServer();
        let allDataSign = {...dataSign, "CertSerial":cert.data, "FileData":file, "signDate": time};
        onSign(allDataSign);
    };

    const onSign = (allDataSign) => {
        axios.post(`http://localhost:6888/api/sign/signpdf`, allDataSign)
            .then((response) => {
                const data = response.data;
                console.log("dataSigned", data)
                if (!data.FileDataSigned) {
                    onFailAction('Có lỗi xảy ra trong quá trình ký!');
                    return ;
                }
                updateSignedContract({...data, "secret":secret});
            })
            .catch((error) => {
                onFailAction(error);
            });
    };

    const updateSignedContract = async (data) => {
        const result = await SignRevokeDestroyPauseServices.updateFileRevokeSigned(data);
        if (result && result.status === 200){
            onSuccessAction("Ký thành công!", () => {
                window.location.reload();
            });
        } else {
            onFailAction("Có lỗi xảy ra !");
        }
    };

    return(
        <div>
            <nav className="navbar navbar-light bg-light">
                <div className="row">
                    <div className="col-md-5">
                        <a className="navbar-brand" href="/#">
                            <img src="/images/logo2.jpg" width="20%" alt="logo"/>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <span className="navbar-brand border border-danger rounded">Ký xác nhận file thu hồi chứng thư số</span>
                    </div>
                </div>
            </nav>
            {!confirm ? (
                <div className="text-center">
                    <button className="btn btn-success" onClick={submitPin}><i className="fas fa-file-signature"/>Xác thực</button>
                </div>

            ) : ("")}

            {canSign ? (
                <React.Fragment>
                    <div className="input-group d-flex justify-content-center p-5">
                        <div className="text-center">
                            <button className="btn btn-success mr-3" onClick={onSignClick}><i className="fas fa-file-signature"/> Ký file xác nhận</button>
                        </div>

                        <div className="text-center">
                            <button className="btn btn-danger" onClick={onDenyClick}><i className="fas fa-file-signature"/>Từ chối ký</button>
                        </div>
                    </div>
                </React.Fragment>
            ) : ""}
            <div className="col-md-12 text-center mt-3">
                <Form>
                    <ModalEnterPin
                        visible={modalPin.visible_modal_password}
                        handleCancel={handleModalConnectCancel}
                        handleDeny={confirmPin}
                        value={modalPin.password}
                        onChange={onChangeDataPin}
                        error={modalPin.error_password}
                        title={'Nhập mã xác thực'}
                    />
                    {confirm ? (
                        <iframe
                            title="Biên bản thanh lý"
                            src={`data:application/pdf;base64,${file}`}
                            height="800px"
                            width="45%"
                        />
                    ):("")}

                </Form>
            </div>

        </div>
    );
};

export default SignRevokeFilePage;