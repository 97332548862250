import Form, { FormComponentProps } from "antd/lib/form";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { match } from "react-router";
import ModalEnterPin from "../../components/common/form/ModalEnterPin";
import {
  loading,
  loading as loadingHelper,
} from "../../components/common/loading/Loading";
import { onFailAction, onSuccessAction } from "../../helpers/SwalCommon";
import { ReSignDk02Services } from "../../services/view-file-dk02/ReSignDk02Services";
import moment from "moment";
import ModalEnterPinCaptcha from "../../components/common/form/ModalEnterPinCaptcha";
import { randomString } from "../../helpers/NewCaCrmHelper";
import { Button } from "antd";

interface Props extends FormComponentProps {
  match: match<{ secret: string; type: string }>;
  history: any;
}

const ViewFileDk02Page: React.FC<Props> = (props) => {
  const [file, setFile] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [codeUi, setCodeUi] = useState("");

  const initialStateModalPin = {
    visible_modal_password: false,
    loading: false,
    title_modal: "",
    base64: "",
    type_file: "",
    password: "",
    error_password: "",
    captcha: "",
    error_captcha: "",
    fileBase64Captcha: "",
    captchaString: "",
  };
  const dataSign = {
    fieldName: "SignatureB [002]",
    typeSign: "1",
    page: "4",
    px: "86",
    py: "61",
    pw: "180",
    ph: "10",
  };

  const [canSign, setCanSign] = useState(false);

  const secret = atob(props.match.params.secret);
  const type = props.match.params.type;

  const getCertificate = async () => {
    loadingHelper.runLoadingBlockUI();
    const result = await ReSignDk02Services.getCertificate(secret, type);
    loadingHelper.stopRunLoading();
    return result;
  };
  const test = () => {};
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const [modalPin, setModalPin] = useState(initialStateModalPin);
  const submitPin = async () => {
    const codeUi = randomString();
    const result = await getCaptcha(codeUi);
    console.log(result);
    setCodeUi(codeUi);
    setModalPin({
      ...modalPin,
      visible_modal_password: true,
      fileBase64Captcha: result.base64Img,
      password: "",
      captcha: "",
      error_captcha: "",
      error_password: "",
    });
  };
  const getFileDk02 = async () => {
    loadingHelper.runLoadingBlockUI();
    const captcha = modalPin.captcha;
    const result = await ReSignDk02Services.getFileDk02(
      secret,
      type,
      modalPin.password,
      codeUi,
      captcha
    );
    setFile(result.data);
    if (result && Number(result.number_signed) === 1) {
      setCanSign(true);
    }
    loadingHelper.stopRunLoading();
    return result;
  };
  const confirmPin = async () => {
    if (modalPin.password.trim() === "") {
      setModalPin({
        ...modalPin,
        error_password: "Mã password không được trống",
        error_captcha: "",
      });
      return false;
    }
    if (modalPin.captcha.trim() === "") {
      setModalPin({
        ...modalPin,
        error_password: "",
        error_captcha: "Mã captcha không được bỏ trống.",
      });
      return false;
    }
    const check = await getFileDk02();
    loading.runLoadingBlockUI();
    if (check.status === 200) {
      setConfirm(true);
      await setModalPin({ ...modalPin, visible_modal_password: false });
    } else if (check.status === 4001) {
      setModalPin({
        ...modalPin,
        error_password: "",
        error_captcha: check.message,
      });
      setTimeout(() => {
        reLoadCaptcha();
      }, 1000 * 2);
    } else {
      setConfirm(false);
      onFailAction(check.message);
      const clearStateModalPin = () => {
        setModalPin({ ...initialStateModalPin });
      };
    }
    loading.stopRunLoading();
  };
  const getCaptcha = async (codeUi) => {
    loadingHelper.runLoadingBlockUI();
    const result = await ReSignDk02Services.getCaptcha(codeUi);
    loadingHelper.stopRunLoading();
    return result;
  };
  const clearStateModalPin = () => {
    setModalPin({ ...initialStateModalPin });
  };
  const reLoadCaptcha = async () => {
    const codeUi = randomString();
    const result = await getCaptcha(codeUi);
    setCodeUi(codeUi);
    setModalPin({
      ...modalPin,
      visible_modal_password: true,
      fileBase64Captcha: result.base64Img,
      captcha: "",
      error_captcha: "",
    });
  };
  // @ts-ignore
  const onChangeDataPin = ({ target: { value } }) => {
    setModalPin({ ...modalPin, password: value });
  };
  const onChangeDataCaptcha = ({ target: { value } }) => {
    setModalPin({ ...modalPin, captcha: value });
  };
  const handleModalConnectCancel = () => {
    clearStateModalPin();
  };

  const onSignClick = async () => {
    let cert = await getCertificate();
    if (!cert.data) {
      onFailAction(cert.message);
      return;
    }
    const dataSend = {
      mst: "",
    };
    const certSerial = cert?.data?.certSerial;
    const sign_ncc_time = cert?.data?.sign_ncc_time;
    const getCertInToken = await ReSignDk02Services.getCertInToken(dataSend);
    console.log("info cert in token:", getCertInToken);
    let checkSerial = false;
    let timeCert = sign_ncc_time;
    if (getCertInToken && getCertInToken.length >= 1) {
      getCertInToken.map((key, values) => {
        if (key.Serial && key.Serial === certSerial) {
          checkSerial = true;
          let validForm =getCertInToken[values].ValidFrom;
          console.log('validForm: ', validForm);
          if (!timeCert){
            let timeFormat = moment(validForm) .format("YYYY-MM-DD HH:mm:ss");
            timeCert = timeFormat;
          }
        }
      });
    } else {
      onFailAction("Không tìm thấy chứng thư số. Vui lòng cắm token.");
      return;
    }
    console.log('timeCert: ', timeCert);
    if (checkSerial && timeCert) {
      const randomSeconds = Math.floor(Math.random() * 61) + 60;
      const timeSign = moment(timeCert)
      .add(randomSeconds, 'seconds')
        .format("DD-MM-YYYY HH:mm:ss");
      console.log('timeSign: ', timeSign);
      let allDataSign = {
        ...dataSign,
        CertSerial: certSerial,
        FileData: file,
        signDate: timeSign,
      };
      console.log('allDataSign: ', allDataSign);
      onSign(allDataSign);
    } else {
      onFailAction(
        "Chứng thư số không hợp lệ. Vui lòng cắm đúng token có chứng thư: " +
          certSerial
      );
    }
  };

  const onSign = (allDataSign) => {
    console.log("allDataSign");
    console.log(allDataSign);
    axios
      .post(`http://localhost:6888/api/sign/signpdf`, allDataSign)
      .then((response) => {
        const data = response.data;
        console.log("dataSigned", data);
        if (!data.FileDataSigned) {
          onFailAction("Có lỗi xảy ra trong quá trình ký!");
          return;
        }
        updateSignedContract({ ...data, secret: secret, type: type });
      })
      .catch((error) => {
        onFailAction(error);
      });
  };

  const updateSignedContract = async (data) => {
    const result = await ReSignDk02Services.updateFileSigned(data);
    if (result && result.status === 200) {
      onSuccessAction("Ký thành công!", () => {
        window.location.reload();
      });
    } else {
      onFailAction("Có lỗi xảy ra !");
    }
  };
  const { REACT_APP_BASE_API_URL } = window['runConfig'];

  const downloadPlugin = () => {
    try {
      const link = `${REACT_APP_BASE_API_URL}software-get-content/FAST_PLUGIN`;
      window.open(link);
    } catch (error) {
      onFailAction("Có lỗi xảy ra!");
    }
  };

  return (
    <div>
      <nav className="navbar navbar-light bg-light">
        <div className="row w-100">
          <div className="col-md-4">
            <a className="navbar-brand" href="/#">
              <img src="/images/logo2.jpg" 
              width="auto" 
              alt="logo" 
              height="40"
              />
            </a>
          </div>
          <div className="col-md-4 text-center align-self-center">
            <span className="navbar-brand">Giấy chứng nhận</span>
          </div>
          <div className="col-md-4 text-right align-self-center">
            <Button
              key="link"
              type="link"
              onClick={downloadPlugin}
              className=""
              title="Vui lòng tải plugin trước khi ký (Nếu chưa có plugin)"
            >
              <span>Tải Plugin ký GCN (Tại đây)</span>
            </Button>
          </div>
        </div>
      </nav>
      <div className="mt-2">
        {!confirm ? (
          <div className="text-center">
            <button className="btn btn-success" onClick={submitPin}>
              <i className="fas fa-file-signature" />
              Xác thực
            </button>
          </div>
        ) : (
          ""
        )}

        {canSign ? (
          <div className="text-center">
            <button className="btn btn-success" onClick={onSignClick}>
              <i className="fas fa-file-signature" /> Ký giấy chứng nhận
            </button>
          </div>
        ) : (
          ""
        )} 
      </div>
      <div className="col-md-12 text-center mt-3">
        <Form>
          {/*<ModalEnterPin*/}
          {/*    visible={modalPin.visible_modal_password}*/}
          {/*    handleCancel={handleModalConnectCancel}*/}
          {/*    handleDeny={confirmPin}*/}
          {/*    value={modalPin.password}*/}
          {/*    onChange={onChangeDataPin}*/}
          {/*    error={modalPin.error_password}*/}
          {/*    title={'Nhập mã xác thực'}*/}
          {/*/>*/}

          <ModalEnterPinCaptcha
            visible={modalPin.visible_modal_password}
            handleCancel={handleModalConnectCancel}
            handleDeny={confirmPin}
            value={modalPin.password}
            valueCaptcha={modalPin.captcha}
            onChange={onChangeDataPin}
            onChangeValueCaptcha={onChangeDataCaptcha}
            errorPass={modalPin.error_password}
            errorCaptcha={modalPin.error_captcha}
            fileBase64Captcha={modalPin.fileBase64Captcha}
            title={"Nhập mã xác thực"}
            isReload={reLoadCaptcha}
          />
          {confirm ? (
            <iframe
              title="Biên bản thanh lý"
              src={`data:application/pdf;base64,${file}`}
              height="800px"
              width="45%"
            />
          ) : (
            ""
          )}
        </Form>
      </div>
    </div>
  );
};

export default ViewFileDk02Page;
