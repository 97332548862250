import Form, { FormComponentProps } from "antd/lib/form";
import React, { useState } from "react";
import { match } from "react-router";
import ModalEnterPin from "../../components/common/form/ModalEnterPin";
import { loading, loading as loadingHelper } from "../../components/common/loading/Loading";
import ModalInputUsernamePasssword from "../../components/common/modal/modal-input-username-password/ModalInputUsernamePasssword";
import { onFailAction, onSuccessAction } from "../../helpers/SwalCommon";
import { SignDK02HSMServices } from "../../services/sign-dk02-hsm/SignDK02HSMServices";

interface Props extends FormComponentProps {
    match: match<{ secret: string}>;
    history: any;
}

const SignDK02HSMPage: React.FC<Props> = props => {
    const [file, setFile] = useState("");
    const [confirm, setConfirm] = useState(false);

    // const dataSign = {
    //     "fieldName":"SignatureB [002]",
    //     "typeSign":"1",
    //     "page": "4",
    //     "px": "86",
    //     "py": "61",
    //     "pw": "180",
    //     "ph": "10"
    // };

    const [canSign, setCanSign] = useState(false);
    const secret = props.match.params.secret;

    const getFileSign = async (password) => {
        loadingHelper.runLoadingBlockUI();
        const dataSend = {
            secret: secret,
            password: password
        };
        const result = await SignDK02HSMServices.getFileCustomerSign(dataSend);
        if (result && result.status === 200 ){
            setFile(result.data);
            if (result.number_signed === 1){
                setCanSign(true);
            }
        }
        loadingHelper.stopRunLoading();
        return result;
    };

    const [modalPin, setModalPin] = useState({
        visible_modal_password: false,
        visible_modal_hsm: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        password: "",
        usernameHSM: "",
        passwordHSM: "",
        error_password: "",
        error_hsm: "",
    });
    const submitPin = () => {
        setModalPin({...modalPin, 'visible_modal_password': true});
    };
    const confirmPin = async () => {
        if (modalPin.password.trim() === "") {
            setModalPin({...modalPin, 'error_password': "Mã password không được trống"});
            return false;
        }
        const check =  await getFileSign(modalPin.password);
        loading.runLoadingBlockUI();
        if (check.status === 200){
            setConfirm(true);
            await setModalPin({...modalPin, 'visible_modal_password': false });
        }else {
            setConfirm(false);
            onFailAction(check.message);
            await setModalPin({...modalPin, 'visible_modal_password': false });
        }
        loading.stopRunLoading();

    };
    // @ts-ignore
    const onChangeDataPin = ({target: {value}}) => {
        setModalPin({...modalPin, 'password': value});
    };
    const handleModalConnectCancel = () => {
        setModalPin({...modalPin,
            'visible_modal_password': false,
            'password': '',
            'error_password': ''
        })
    };


    const submitHSM = () => {
        setModalPin({...modalPin, 'visible_modal_hsm': true});
    };
    const confirmHSM = async () => {
        if (modalPin.passwordHSM.trim() === "" || modalPin.usernameHSM.trim() === "") {
            setModalPin({...modalPin, 'error_hsm': "Tài khoản hoặc mật khẩu không được trống."});
            return false;
        }
        const dataSend = {
            secret: secret,
            username_hsm: modalPin.usernameHSM,
            password_hsm: modalPin.passwordHSM,
            file: file
        };
        const signAction = await SignDK02HSMServices.signAction(dataSend);
        loading.runLoadingBlockUI();
        if (signAction.status === 200){
            onSuccessAction(signAction.message, () => {
                setFile(signAction.fileSigned);
                setCanSign(false)
                setModalPin({...modalPin, 'visible_modal_hsm': false});
            });
        }else {
            if (signAction.message){
                onFailAction(signAction.message);
                await setModalPin({...modalPin, 'visible_modal_password': false });
            }else {
                onFailAction("Có lỗi sảy ra trong quá trình ký");
                await setModalPin({...modalPin, 'visible_modal_password': false });
            }
        }
        loading.stopRunLoading();
    };
    const onChangeDataUsername = ({target: {value}}) => {
        setModalPin({...modalPin, 'usernameHSM': value});
    };
    const onChangeDataPassWord = ({target: {value}}) => {
        setModalPin({...modalPin, 'passwordHSM': value});
    };
    const handleModalHSMCancel = () => {
        setModalPin({...modalPin, 
            'visible_modal_hsm': false,
            'usernameHSM': '',
            'passwordHSM': '',
            'error_hsm': '',
        })
    };

    return(
        <div>
            <nav className="navbar navbar-light bg-light">
                <div className="row">
                    <div className="col-md-5">
                        <a className="navbar-brand" href="/#">
                            <img src="/images/logo2.jpg" width="20%" alt="logo"/>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <span className="navbar-brand border border-danger rounded">Ký xác nhận file DK02 HSM</span>
                    </div>
                </div>
            </nav>
            {!confirm ? (
                <div className="text-center">
                    <button className="btn btn-success" onClick={submitPin}><i className="fas fa-file-signature"/>Xác thực</button>
                </div>

            ) : ("")}

            {canSign ? (
                <React.Fragment>
                    <div className="input-group d-flex justify-content-center p-5">
                        <div className="text-center">
                            <button className="btn btn-success mr-3" onClick={submitHSM}><i className="fas fa-file-signature"/> Ký file xác nhận</button>
                        </div>
                    </div>
                </React.Fragment>
            ) : ""}
            <div className="col-md-12 text-center mt-3">
                <Form>
                    <ModalInputUsernamePasssword
                        visible={modalPin.visible_modal_hsm}
                        handleCancel={handleModalHSMCancel}
                        handleDeny={confirmHSM}
                        username={modalPin.usernameHSM}
                        password={modalPin.passwordHSM}
                        onChangeUsername={onChangeDataUsername}
                        onChangePassword={onChangeDataPassWord}
                        error={modalPin.error_hsm}
                    />
                    <ModalEnterPin
                        visible={modalPin.visible_modal_password}
                        handleCancel={handleModalConnectCancel}
                        handleDeny={confirmPin}
                        value={modalPin.password}
                        onChange={onChangeDataPin}
                        error={modalPin.error_password}
                        title={'Nhập mã xác thực'}
                    />
                    {confirm ? (
                        <iframe
                            title="Biên bản thanh lý"
                            src={`data:application/pdf;base64,${file}`}
                            height="800px"
                            width="45%"
                        />
                    ):("")}

                </Form>
            </div>

         </div>
    );
};

export default SignDK02HSMPage;