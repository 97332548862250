import React, {useEffect, useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import {Card, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {loading} from "components/common/loading/Loading";
import _ from "lodash";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import {RequestCertificateGroupService} from "../../services/request-certificate-group/RequestCertificateGroupServices";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import {RequestDigitalCertificatePersonalServices} from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import moment from "moment";
import {formatMoney, handleDateData, handleFormatString} from './../../helpers/NewCaCrmHelper';
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";

interface Props extends FormComponentProps {
    user: any;
    history: any;
}

const objectDate1 = {
    1: 'represen_passport_date'
};
const objectDate2 = {
    1: 'provide_date'
};

export const RequestCertificateGroup: React.FC<Props> = props => {
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState([]);
    const [disableCode, setDisableCode] = useState(false);
    const [disableObject, setDisableObject] = useState(true);
    const [typeObject, setTypeObject] = useState(Number);

    const [file, setFile] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [disableTaxCode, setDissableTaxCode] = useState(false);
    const [disableIdenType, setDissableIdenType] = useState(false);
    const [disableTypeDevice, setDisableTypeDevice] = useState(false);
    const [disableField, setDisableField] = useState(false);

    const storeRequest = status => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();

                    const val= {
                        ...values,
                        status
                    };

                    let valuesConvert = val;
                    if (val.represen_passport_date !== undefined && val.represen_passport_date !== null ){
                        valuesConvert = handleDateData(val, objectDate1);
                    }
                    if (val.provide_date !== undefined && val.provide_date !== null ){
                        valuesConvert = handleDateData(val, objectDate2);
                    }

                    const data = await RequestCertificateGroupService.store(valuesConvert);
                    if (data && Number(data.status) === 422) {
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else if (data && Number(data.status) === 200) {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-cts-to-chuc");
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi lưu !");
                } finally {
                    loading.stopRunLoading();
                }
            }else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const onGenerateFile = async (typeFile) => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    setVisibleModal(true);
                    setLoadingModal(true);
                    const val= {
                        ...values,
                        typeFile: typeFile
                    };

                    let valuesConvert = val;
                    if (val.represen_passport_date !== undefined && val.represen_passport_date !== null ){
                        valuesConvert = handleDateData(val, objectDate1);
                    }
                    if (val.provide_date !== undefined && val.provide_date !== null ){
                        valuesConvert = handleDateData(val, objectDate2);
                    }
                    const data = await RequestCertificateGroupService.previewFile(valuesConvert);
                    setFile(data.base64);
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem trước file!");
                    setVisibleModal(false);
                } finally {
                    setLoadingModal(false);
                }
            }else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onOkModal = () => {
        setVisibleModal(false);
        setFile("");
    };

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    const changeObject = async (e)=>{
        if (props.form.getFieldValue("code").trim() === ""){
            onFailAction("Bạn chưa nhập định danh.");
        }
        setTypeObject(e.target.value);
        let idenType = props.form.getFieldValue('identity_type');
        let code = props.form.getFieldValue('code');
        let object = e.target.value;
        await fetchInfo(code, object, idenType);

        setTypeObject(props.form.getFieldValue("object"));
        let type_search = 4;
        let type = props.form.getFieldValue("object");
        let obj = 3;
        let type_device = props.form.getFieldValue("type_device");
        const catePackage = await getCateServicePackage(type_search, type, obj, type_device);
        setCateServicePackageAmount(catePackage.data);
        setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
    };
    const getCateServicePackage = async (type_search, type, obj, type_device) =>{
        return  await RequestDigitalCertificatePersonalServices.getListCateServicePackage(type_search, type, obj,type_device);
    };
    const changeTypeDevice = async (e) =>{
        const type_search = 4;
        const type = typeObject;
        let obj = 3;
        const type_device = e.target.value;
        const data = await RequestDigitalCertificatePersonalServices.getListCateServicePackage(type_search, type, obj,type_device);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.form.setFieldsValue({
            package_id: '',
            package_price: ''
        });
    };
    const changeCode = async (e) =>{
        if (e.target.value){
            setDisableObject(false);
        }else{
            setDisableObject(true);
            props.form.setFields({"object": ''});
        }
    };

    const fetchInfo = async (code, object, idenType) => {
        loading.runLoadingBlockUI();
        const result = await RequestCertificateGroupService.getInfo(code, object, idenType);
            if (result && result.status === 200){
                if (Number(object) === 1 || Number(object) === 3){
                    props.form.setFieldsValue({
                        type_legal: result.data.type_legal.toString(),
                        identity_type: result.data.identity_type.toString(),
                        code: result.data.code,
                        provide_organization: result.data.provide_organization,
                        tax_code: result.data.tax_code,
                        fullname: result.data.fullname,
                        address: result.data.address,
                        email: result.data.email,
                        phone: result.data.phone,
                        type_device: result.data.request.type_device.toString(),
                        represent_fullname: result.data.represent_fullname,
                        represen_passport: result.data.represen_passport,
                        represen_passport_place: result.data.represen_passport_place,
                        represen_position: result.data.represen_position,
                        represen_email: result.data.represen_email,
                        represen_phone: result.data.represen_phone,
                        sp_fullname: result.data.request.requestsupport.fullname,
                        sp_position: result.data.request.requestsupport.position,
                        sp_email: result.data.request.requestsupport.email,
                        sp_phone: result.data.request.requestsupport.phone,
                    });
                    if(result.data.represen_passport_date){
                        props.form.setFieldsValue({
                            represen_passport_date: moment(result.data.represen_passport_date)
                        })
                    }
                    if(result.data.provide_date){
                        props.form.setFieldsValue({
                            provide_date: moment(result.data.provide_date)
                        })
                    }
                }
            }else if(result && result.status === 101 && result.data !== null){
                const province = await RequestCertificateGroupService.getProvince();
                setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
                const district = await RequestCertificateGroupService.getDistrictByProvince(result.data.province_code);
                setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
                    props.form.setFieldsValue({
                        fullname: result.data.fullname,
                        address: result.data.address,
                    })
                if (result.data.province_code !== null && result.data.district_code !== null){
                    props.form.setFieldsValue({
                        province_code: result.data.province_code.toString(),
                        district_code: result.data.district_code.toString(),
                    });
                }else {
                    props.form.setFieldsValue({
                        province_code: "",
                        district_code: "",
                    });
                }
            }
            if (Number(object) === 2){
                if (Number(result.status) === 101){
                    onFailAction("Mã định danh không tồn tại trong hệ thống!", () => {
                        props.form.setFieldsValue({object:`1`.toString()});
                        setTypeObject(props.form.getFieldValue('object'));
                        setDisableCode(false);
                    }, "warning", "");
                }else {
                    setDisableCode(true);
                    setDissableIdenType(true);
                }
            }
            props.form.resetFields(['package_id','package_price']);
            loading.stopRunLoading();
    };
    const getProvince = async () => {
        const province = await RequestCertificateGroupService.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };
    const onChangeProvince = async (e) =>{
        if(e){
            const district = await RequestCertificateGroupService.getDistrictByProvince(e);
            setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
        }
        props.form.resetFields(['district_code']);

    };
    const onChangeAdjourn = async e =>{
        if (e){
            loading.runLoadingBlockUI();
            const identity_code = props.form.getFieldValue("code");
            const dataRequestCert = await RequestCertificateGroupService.getRequestBySerial(e.target.value, identity_code);
            if(dataRequestCert.status === 200){
                if (dataRequestCert.data.status === 2){
                    onFailAction("Chứng thư số đang chờ thu hồi!!!", () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 3){
                    onFailAction("Chứng thư số đã thu hồi!!!", () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 4){
                    onFailAction("Chứng thư số đang chờ hủy!!!", () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 5){
                    onFailAction("Chứng thư số đã hủy!!!", () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 6){
                    onFailAction("Chứng thư số đang chờ dừng!!!", () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 7){
                    onFailAction("Chứng thư số đã dừng!!!", () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 8){
                    onFailAction("Chứng thư số đã xóa!!!", () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 1 || dataRequestCert.data.status === 9){
                    setDisableField(false);
                    props.form.setFieldsValue({
                        type_legal: dataRequestCert.data.requestcertificate.organization.type_legal.toString(),
                        code: dataRequestCert.data.requestcertificate.organization.code,
                        provide_organization: dataRequestCert.data.requestcertificate.organization.provide_organization,
                        provide_date: moment(dataRequestCert.data.requestcertificate.organization.provide_date),
                        tax_code: dataRequestCert.data.requestcertificate.organization.tax_code,
                        fullname: dataRequestCert.data.requestcertificate.organization.fullname,
                        address: dataRequestCert.data.requestcertificate.organization.address,
                        email: dataRequestCert.data.requestcertificate.organization.email,
                        phone: dataRequestCert.data.requestcertificate.organization.phone,

                        type_device: dataRequestCert.data.requestcertificate.type_device.toString(),

                        represent_fullname: dataRequestCert.data.requestcertificate.organization.represent_fullname,
                        represen_passport: dataRequestCert.data.requestcertificate.organization.represen_passport,
                        represen_passport_place: dataRequestCert.data.requestcertificate.organization.represen_passport_place,
                        represen_position: dataRequestCert.data.requestcertificate.organization.represen_position,
                        represen_email: dataRequestCert.data.requestcertificate.organization.represen_email,
                        represen_phone: dataRequestCert.data.requestcertificate.organization.represen_phone,
                        sp_fullname: dataRequestCert.data.requestcertificate.requestsupport.fullname,
                        sp_position: dataRequestCert.data.requestcertificate.requestsupport.position,
                        sp_email: dataRequestCert.data.requestcertificate.requestsupport.email,
                        sp_phone: dataRequestCert.data.requestcertificate.requestsupport.phone,
                    });

                    if(dataRequestCert.data.requestcertificate.organization.represen_passport_date){
                        props.form.setFieldsValue({
                            represen_passport_date: moment(dataRequestCert.data.requestcertificate.organization.represen_passport_date)
                        })
                    }
                    if(dataRequestCert.data.requestcertificate.organization.provide_date){
                        props.form.setFieldsValue({
                            provide_date: moment(dataRequestCert.data.requestcertificate.organization.provide_date)
                        })
                    }
                    setTypeObject(props.form.getFieldValue("object"));
                    let type_search = 4;
                    let type = props.form.getFieldValue("object");
                    let obj = 3;
                    let type_device = props.form.getFieldValue("type_device");
                    const catePackage = await getCateServicePackage(type_search, type, obj, type_device);
                    setCateServicePackageAmount(catePackage.data);
                    setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
                }
            }
            if (dataRequestCert.status === 102){
                onFailAction("Serial chứng thư số không phù hợp!!!", () => {
                    props.form.setFieldsValue({
                        serial_cts : ''
                    });
                }, "warning", "");
            }
            if (dataRequestCert.status === 103){
                if (dataRequestCert.data.status === 2){
                    onFailAction("Chứng thư số đang chờ thu hồi!!!", () => {
                        setDisableField(true);
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 3){
                    onFailAction("Chứng thư số đã thu hồi!!!", () => {
                        setDisableField(true);
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 4){
                    onFailAction("Chứng thư số đang chờ hủy!!!", () => {
                        setDisableField(true);
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 5){
                    onFailAction("Chứng thư số đã hủy!!!", () => {
                        setDisableField(true);
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 6){
                    onFailAction("Chứng thư số đang chờ dừng!!!", () => {
                        setDisableField(true);
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 7){
                    onFailAction("Chứng thư số đã dừng!!!", () => {
                        setDisableField(true);
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 8){
                    onFailAction("Chứng thư số đã xóa!!!", () => {
                        setDisableField(true);
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "");
                }
                if (dataRequestCert.data.status === 1 || dataRequestCert.data.status === 9){
                    setDisableField(false);
                    props.form.setFieldsValue({
                        type_device: dataRequestCert.data.requestcertificate.type_device.toString(),
                    });
                    setDisableTypeDevice(true);
                    let type_search = 4;
                    let type = props.form.getFieldValue("object");
                    let obj = 3;
                    let type_device = dataRequestCert.data.requestcertificate.type_device.toString();
                    const catePackage = await getCateServicePackage(type_search, type, obj, type_device);
                    setCateServicePackageAmount(catePackage.data);
                    setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
                }
            }
            if (dataRequestCert.status === 101){
                onFailAction("Không tồn tại chứng thư số phù hợp với serial!!!", () => {
                    props.form.setFieldsValue({
                        serial_cts : ''
                    });
                }, "warning", "");
            }
        }
    };
    const onChangeIdenType = async (e) => {
        if (e){
            props.form.resetFields([
                'tax_code',
                'code',
                'object',
                'fullname',
                'address',
                'phone',
                'email',
                'province_code',
                'district_code',
            ]);
            if (Number(e) === 1){
                setDissableTaxCode(true);
                setLabelCode("Mã số thuế");
            }if (Number(e) === 2){
                setDissableTaxCode(false);
                setLabelCode("Mã ngân sách");
            }if (Number(e) === 3){
                setDissableTaxCode(false);
                setLabelCode("Số quyết định");
            }if (Number(e) === 4){
                setDissableTaxCode(false);
                setLabelCode("Mã bảo hiểm xã hội");
            }if (Number(e) === 5){
                setDissableTaxCode(false);
                setLabelCode("Giấy phép đầu tư");
            }
        }

    };
    const onChangeCodeTaxCode = async (e) => {
        if (e && Number(props.form.getFieldValue("identity_type")) === 1){
            props.form.setFieldsValue({
                tax_code: e.target.value
            });
        }
        props.form.resetFields([
            'object',
            'fullname',
            'address',
            'phone',
            'email',
            'province_code',
            'district_code',
        ]);
    };
    const onChangeFormatInput = (name, value, type) =>{
        const strFormart = handleFormatString(value, type);
        props.form.setFieldsValue({[name]: strFormart})
    };
    const onChange = async value => {
        const selectdCate: any = _.find(cateServicePackageAmount, { id: parseInt(value) });
        if(selectdCate){
            const price = formatMoney(selectdCate.price);
            props.form.setFieldsValue({ package_price: price });
        }
    };
    const onChangePrice = (e) =>{
        const price = formatMoney(e.target.value);
        props.form.setFieldsValue({package_price: price})
    };
    const onChangeRepresentName = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_fullname: e.target.value
            });
        }
        props.form.setFieldsValue({
            represent_fullname : ''
        });
    };
    const onChangeRepresentPosition = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_position: e.target.value
            });
        }
        props.form.setFieldsValue({
            represen_position : ''
        });
    };
    const onChangeRepresentEmail = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_email: e.target.value
            });
        }
        props.form.setFieldsValue({
            represen_email : ''
        });
    };
    const onChangeRepresentPhone = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_phone: e.target.value
            });
        }
        props.form.setFieldsValue({
            represen_phone : ''
        });
    };
    useEffect(() => {
        getProvince();
        // eslint-disable-next-line
    }, []);
    return (
        <PageWrapper title="Yêu cầu chứng thư số tổ chức ">
            <ModalDisplayFile
                titleModal="File"
                visibleModal={visibleModal}
                loadingModal={loadingModal}
                fileBase64={file}
                onOkModal={()=>onOkModal()}
            />
            <InputWithLabel
                wrapClass={''}
                name={'isUpdate'}
                label={''}
                form={props.form}
                defaultValue={1}
                hidden={true}
            />
            <Form>
                <Card className="m-r-15-i m-l-15-i mt-2" title={<label>Thông tin gói chứng thư số</label>} size="small">
                    {/*line 1*/}
                    <div className="input-group">
                        <SelectWithLabel
                            options={{
                                1: 'Mã số thuế',
                                2: 'Mã ngân sách',
                                3: 'Số quyết định',
                                4: 'Mã bảo hiểm xã hội',
                                5: 'Giấy phép đầu tư'
                            }}
                            name="identity_type"
                            wrappedClass="col-md-2"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            onChange={onChangeIdenType}
                            isDisabled={disableIdenType}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            isRequired={true}
                            wrapClass="col-md-2"
                            maxLength={16}
                            onChange={changeCode}
                            onBlur={onChangeCodeTaxCode}
                            isDisabled={disableCode}
                        />
                        <RadioWithLabel
                            options={{1: "Cấp mới", 2: "Gia hạn", 3: "Chuyển đổi"}}
                            label="Đối tượng"
                            name="object"
                            wrappedClass="col-md-4 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            onChange={changeObject}
                            isDisabled={disableObject}
                        />
                        {Number(props.form.getFieldValue("object")) === 2 ? (
                            <React.Fragment>
                                <InputWithLabel
                                    form={props.form}
                                    label="Serial CTS"
                                    name="serial_cts"
                                    wrapClass="col-md-2"
                                    isRequired={true}
                                    onBlur={onChangeAdjourn}
                                />
                                <SelectWithLabel
                                    options={{1: "Token mới", 2: "Token cũ"}}
                                    name="token_type"
                                    wrappedClass="col-md-2"
                                    form={props.form}
                                    label={"Loại token"}
                                    isRequired={true}
                                    isDisabled={disableField}
                                />
                            </React.Fragment>
                        ): ('')}

                    </div>

                    <div className="input-group">
                        <RadioWithLabel
                            options={{1: "Token", 2: "HSM"}}
                            label="Thiết bị đầu cuối thuê bao"
                            name="type_device"
                            wrappedClass="col-md-2 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            onChange={changeTypeDevice}
                            isDisabled={disableTypeDevice}
                        />
                        <SelectWithLabel
                            options={cateServicePackage}
                            name="package_id"
                            wrappedClass="col-md-2"
                            form={props.form}
                            label={"Gói dịch vụ"}
                            isRequired={true}
                            onChange={onChange}
                            isDisabled={disableField}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Giá bán"
                            name="package_price"
                            wrapClass="col-md-2"
                            isRequired={true}
                            isDisabled={disableField}
                            onBlur={onChangePrice}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số lượng"
                            name="quantity"
                            wrapClass="col-md-2"
                            isRequired={true}
                            type={'number'}
                            defaultValue={1}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Thông tin doanh nghiệp</label>} size="small">
                    {/*line 1*/}
                    <div className="input-group">
                        <RadioWithLabel
                            options={{1: "Giấy ĐKKD", 2: "Giấy phép đầu tư", 3: "Quyết định thành lập"}}
                            label="Giấy tờ pháp lý"
                            name="type_legal"
                            wrappedClass="col-md-5 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            isDisabled={disableField}
                        />
                        <InputFileUpload
                            classWrapped="col-md-3"
                            label="Tải file giấy tờ pháp lý"
                            name="file_legal"
                            form={props.form}
                            isRequired={true}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                            isDisabled={disableField}
                        />
                        <SelectDateWithLabel
                            name="provide_date"
                            form={props.form}
                            wrapClass="col-md-2"
                            label="Ngày cấp"
                            isDisabled={disableField}
                            rules={[
                                {
                                    validator: function(rule, value, callback) {
                                        if (value && value > moment()) {
                                            callback("Ngày cấp phải nhỏ hơn ngày hiện tại");
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                }
                            ]}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="provide_organization"
                            wrapClass="col-md-2"
                            maxLength={255}
                            isDisabled={disableField}
                        />
                    </div>
                    {/*line2*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="MST (nếu có)"
                            name="tax_code"
                            wrapClass="col-md-2"
                            maxLength={16}
                            isDisabled={disableTaxCode}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên doanh nghiệp"
                            name="fullname"
                            wrapClass="col-md-4"
                            isRequired={true}
                            maxLength={255}
                            isDisabled={disableField}
                            onBlur={(e) =>  onChangeFormatInput('fullname', e.target.value, 3)}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-2"
                            isRequired={true}
                            maxLength={16}
                            isDisabled={disableField}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-4"
                            isRequired={true}
                            maxLength={255}
                            isDisabled={disableField}
                            note={"Thông tin hiển thị trong CTS của doanh nghiệp"}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-8"
                            maxLength={255}
                            isDisabled={disableField}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-2"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isRequired={true}
                            onChange={onChangeProvince}
                            isDisabled={disableField}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-2"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                            isDisabled={disableField}
                        />
                        <p style={{fontSize: '12px', color: "red", marginLeft: '1.5em'}}>Lưu ý : Thông tin Số điện thoại và Email bắt buộc nhập chính xác để xác minh khách hàng và nhận hỗ trợ dịch vụ từ NCC</p>
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Người đại diện </label>} size="small">
                    {/*line 1*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="represent_fullname"
                            wrapClass="col-md-3"
                            isRequired={true}
                            maxLength={255}
                            isDisabled={disableField}
                            onBlur={(e) =>  onChangeFormatInput('represent_fullname', e.target.value, 1)}
                            onChange={onChangeRepresentName}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số CMND"
                            name="represen_passport"
                            wrapClass="col-md-3"
                            maxLength={16}
                            isDisabled={disableField}
                        />
                        <InputFileUpload
                            classWrapped="col-md-3"
                            label="Tải file CMND/Hộ chiếu"
                            name="file_deputy_passport"
                            form={props.form}
                            isRequired={true}
                            extentionsAllow={['pdf', 'PDF', 'png', 'jpg', 'jpeg']}
                            accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                            isDisabled={disableField}
                        />
                        <SelectDateWithLabel
                            name="represen_passport_date"
                            form={props.form}
                            wrapClass="col-md-3"
                            label="Ngày cấp"
                            isDisabled={disableField}
                            rules={[
                                {
                                    validator: function(rule, value, callback) {
                                        if (value && value > moment()) {
                                            callback("Ngày cấp phải nhỏ hơn ngày hiện tại");
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                }
                            ]}
                        />

                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="represen_passport_place"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={disableField}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="represen_position"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={disableField}
                            onChange={onChangeRepresentPosition}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="represen_email"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={disableField}
                            onChange={onChangeRepresentEmail}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="represen_phone"
                            wrapClass="col-md-3"
                            maxLength={16}
                            isDisabled={disableField}
                            onChange={onChangeRepresentPhone}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Thông tin đầu mối quản lý sử dụng</label>} size="small">
                        <React.Fragment>
                            <div className="input-group">
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Họ tên đầu mối"
                                        name="sp_fullname"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                        isDisabled={disableField}
                                        onBlur={(e) =>  onChangeFormatInput('sp_fullname', e.target.value, 1)}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ"
                                        name="sp_position"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                        isDisabled={disableField}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="sp_email"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                        maxLength={255}
                                        isDisabled={disableField}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="sp_phone"
                                        wrapClass="col-md-3"
                                        maxLength={16}
                                        isDisabled={disableField}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Mẫu đăng ký</label>} size="small">
                    <div className="input-group">
                        <RadioWithLabel
                            options={{1: "Điện tử", 2: "Giấy", 3: "Scan"}}
                            label="Loại hồ sơ"
                            name="type_docurment"
                            wrappedClass="col-md-3 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            isDisabled={disableField}
                        />
                        <div className="col-md-3">
                            <label className="invisible">11111998</label>
                            <button onClick={() => onGenerateFile(1)} className="btn btn-outline-success form-control">
                                Tải xuống DK-01
                            </button>

                        </div>
                        <div className="col-md-3">
                            <label className="invisible">11111998</label>
                            <button onClick={() => onGenerateFile(3)} className="btn btn-outline-primary form-control">
                                Tải xuống DK-03
                            </button>
                        </div>
                    </div>
                    <div className='input-group'>
                        <InputFileUpload
                            classWrapped="col-md-3 hidden"
                            label=""
                            name={"abc"}
                            form={props.form}
                        />
                        <InputFileUpload
                            classWrapped="col-md-3"
                            label="Tải file DK-01 (Đã ký)"
                            name="file_register_paper"
                            form={props.form}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                            warning={true}
                            isDisabled={disableField}
                        />

                        <InputFileUpload
                            classWrapped="col-md-3"
                            label="Tải file DK-03 (Đã ký)"
                            name="file_dk_03"
                            form={props.form}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                            isDisabled={disableField}
                        />
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-5">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest(1);
                        }}
                        label="Lưu nháp"
                        className={"btn btn-primary btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest(2);
                        }}
                        label="Trình duyệt"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.push("/yeu-cau-cts-to-chuc");
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestCertificateGroupCreate = Form.create<Props>({
    name: "RequestCertificateGroup"
})(RequestCertificateGroup);

export default WrappedRequestCertificateGroupCreate;
