import React, { Component } from "react";
import { Modal } from 'antd';
import { Input } from 'antd';
import ValidateMessageError from "../../form/validate-message-error/ValidateMessageError";

interface Props {
    handleCancel?: any;
    handleDeny?: any;
    value?: string;
    username?: string;
    password?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    onChangeUsername?: any;
    onChangePassword?: any;
    error? : string;
}
interface State {
    username: string;
    password: string;
}

export default class ModalInputUsernamePasssword extends Component<Props, State> {
    state = {
        username : "",
        password : ""
    };
    componentDidMount() {
    }
    render() {
        return (
            <div>
                <Modal
                    title={this.props.title ? this.props.title :"Đăng nhập ký"}
                    visible={this.props.visible}
                    onOk={this.props.handleDeny}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Đồng ý"
                    cancelText="Đóng"
                    className="modal-lg"
                    width = '23%'
                    closable={false}
                    // bodyStyle={{}}
                >
                    <Input
                        placeholder={this.props.title ? this.props.title :"Tên đăng nhập"}
                        className={"form-control mb-3"}
                        required={true}
                        value={this.props.username}
                        onChange={this.props.onChangeUsername}
                    />
                    <Input
                        placeholder={this.props.title ? this.props.title :"Mật khẩu"}
                        className={"form-control "}
                        required={true}
                        value={this.props.password}
                        onChange={this.props.onChangePassword}
                    />
                    <ValidateMessageError error={this.props.error}></ValidateMessageError>
                </Modal>
            </div>
        );
    }
}
