import React, {useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import {RouteComponentProps} from "react-router";
import {reloadPage} from "helpers/NewCaCrmHelper";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import { RequestSupportServices } from "../../services/request-support/RequestSupportServices";
import WrappedRequestSupportSearchForm from "./search/WrappedRequestSupportSearchForm";
import { Tag } from "antd";
import moment from "moment";
import ModalDetail from './search/ModalDetail';
import { FormComponentProps } from 'antd/lib/form';
import {match} from "react-router";

interface Props extends RouteComponentProps {
}
interface Props extends FormComponentProps, RouteComponentProps {
    match: match<{ id: string }>;
    history: any;
    location?:any;
  }

export const RequestSupport: React.FC<Props> = props => {

    const renderActionButton = (text, record, index) => {
        return (
            <TableActionButton
                permissionUpdate=""
                onClickPreviewButton={() =>
                    {
                        onClickModalDetail(record);
                    }
                }
            />
        );
    };
    const [modalDetail, setModalDetail] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        data: {}
    });

    const [idRecordClick, setIdRecordClick] = useState();
    const onClickModalDetail = async (record) => {
        setIdRecordClick(record.id)
        setModalDetail({
            ...modalDetail,
            visible_modal: true,
            data: record,
            loading: true,
            title_modal: "Xem chi tiết cầu hỗ trợ",
        });
    };

    const formatDateTime = (date: string) => {
        if (date) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return "";
        }
    };

    const columns = [
        {
            title: "Mã định danh",
            dataIndex: "code",
        },
        {
            title: "Serial CTS",
            dataIndex: "serial_number",
        },
        {
            title: "Mã Token",
            dataIndex: "token_code"
        },
        {
            title: "Tên khách hàng",
            dataIndex: "name"
        },
        {
            title: "Loại yêu cầu",
            dataIndex: "",
            render: (e) => {
                if (e.type === 1) {
                    return (<Tag color="geekblue">Điều chỉnh</Tag>);
                }
                if (e.type === 2) {
                    return (<Tag color="cyan">Gia hạn</Tag>);
                }
                if (e.type === 3) {
                    return (<Tag color="volcano">Tạm dừng</Tag>);
                }
                if (e.type === 4) {
                    return (<Tag color="red">Thu hồi</Tag>);
                }
            }
        },
        {
            title: "Trạng thái",
            dataIndex: "",
            render: (e) => {
                if (e.status === 1) {
                    return (<Tag color="volcano">Chờ xử lý</Tag>);
                }
                if (e.status === 2) {
                    return (<Tag color="red">Từ chối xử lý</Tag>);
                }
                if (e.status === 3) {
                    return (<Tag color="volcano">Chờ hỗ trợ</Tag>);
                }
                if (e.status === 4) {
                    return (<Tag color="green">Hoàn thành</Tag>);
                }
            }
        },
        {
            title: "Ngày tiếp nhận",
            dataIndex: "",
            render: (e) => formatDateTime(e.created_at)
        },
        {
            title: "Người xử lý",
            dataIndex: "handle.fullname",
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        }
    ];
    const fetch = async (params = {}) => {
        try {
            return await RequestSupportServices.getListIndex(params);
        } catch (error) {
            onFailAction(error.message);
        }
    };

    const handleModalDetailCancel = () => {
        setModalDetail({ ...modalDetail, visible_modal: false });
    };

    const handleModalDetailDeny = async (reason) => {

        const {location, history} = props;
        try {
            await RequestSupportServices.denyRequest(idRecordClick, reason );
            reloadPage(location, history);
            onSuccessAction("Từ chối yêu cầu thành công!");
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi từ chối!");
        }
        setModalDetail({ ...modalDetail, visible_modal: false });
    };
    const handleModalDetailAccept = async () => {
        const {location, history} = props;
        try {
            await RequestSupportServices.approveRequest(idRecordClick);
            reloadPage(location, history);
            onSuccessAction("Xác nhận yêu cầu thành công!");
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xác nhận!");
        }
        setModalDetail({ ...modalDetail, visible_modal: false });
    };

    return (
        <PageWrapper title="Danh sách yêu cầu hỗ trợ">
            <WrappedRequestSupportSearchForm/>
            <Table columns={columns} onFetchData={fetch}/>

            <ModalDetail
                modalVisible={modalDetail.visible_modal}
                onCancelModelDecline = {handleModalDetailCancel}
                onOkModalDecline = {handleModalDetailDeny}
                onOkModalAccept = {handleModalDetailAccept}
                data={modalDetail.data}
                loading={false}
            />
        </PageWrapper>
    );
};