import {RequestSupport} from "../../../pages/request-support/RequestSupport";

const REQUEST_SUPPORT_ROUTES = [
    {
        component: RequestSupport,
        link: "/yeu-cau-ho-tro",
        permission: "sale-list-request-support",
        isExact: true
    }
];

export default REQUEST_SUPPORT_ROUTES;
