import React, {useState} from "react";
import {match} from "react-router";
import Form, {FormComponentProps} from "antd/lib/form";
import {loading, loading as loadingHelper} from "../../components/common/loading/Loading";
import {onFailAction, onSuccessAction} from "../../helpers/SwalCommon";
import axios from "axios";
import ModalEnterPin from "../../components/common/form/ModalEnterPin";
import {SignRevokeFileCompensationServices} from "../../services/sign-file-compensation/SignRevokeFileCompensationServices";
import {formatReadBase64} from "../../helpers/NewCaCrmHelper";
import ModalUploadFile from "../../components/common/modal/upload-file/ModalUploadFile";
import BaseServices from "../../services/base/BaseServices";

interface Props extends FormComponentProps {
    match: match<{ secret: string }>;
    history: any;
}

const SignRevokeCompensationPage: React.FC<Props> = props => {
    const initialStateModalImport = {
        visible_modal: false,
        loading: false,
        error: "",
    }
    const [file, setFile] = useState("");
    const [fileSign, setFileSign] = useState("");
    const [fileName, setFileName] = useState("");
    const [confirm, setConfirm] = useState(false);

    const dataSign = {
        "fieldName": "SignatureB [002]",
        "typeSign": "1",
        "page": "4",
        "px": "86",
        "py": "61",
        "pw": "180",
        "ph": "10"
    };
    const [canSign, setCanSign] = useState(false);

    const [modalPin, setModalPin] = useState({
        visible_modal_password: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        password: "",
        error_password: "",
    });
    const [modalImport, setModalImport] = useState(initialStateModalImport)

    const secret = props.match.params.secret;

    /**
     * Modal Pin
     */
    const handleModalConnectCancel = () => {
        setModalPin({...modalPin, visible_modal_password: false, error_password: "", password: ""})
    };
    const confirmPin = async () => {
        if (modalPin.password.trim() === "") {
            setModalPin({...modalPin, error_password: "Mã xác thực không được trống"});
            return false;
        }
        const check = await getFileSign(modalPin.password);
        loading.runLoadingBlockUI();
        if (check.status === 200) {
            setConfirm(true);
            await setModalPin({...modalPin, visible_modal_password: false});
        } else {
            setConfirm(false);
            onFailAction(check.message);
            await setModalPin({...modalPin, visible_modal_password: false});
        }
        loading.stopRunLoading();
    };
    const onChangeDataPin = ({target: {value}}) => {
        setModalPin({...modalPin, password: value});
    };
    const submitPin = () => {
        setModalPin({...modalPin, visible_modal_password: true});
    };
    const getFileSign = async (password) => {
        loadingHelper.runLoadingBlockUI();
        const dataSend = {
            secret: secret,
            password: password
        };
        const result = await SignRevokeFileCompensationServices.getFileCustomerSign(dataSend);
        if (result && result.status === 200) {
            let contentRead = formatReadBase64(result.data);
            setFile(contentRead);
            setFileSign(result.data);
            if (result.number_signed === null || Number(result.number_signed) === 0) {
                setCanSign(true);
            }
        }
        loadingHelper.stopRunLoading();
        return result;
    };

    /**
     * Modal upload file
     */
    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            setModalImport({...modalImport, error: ""})
            const fileSize = files.size / 1024 / 1024;
            if (fileSize > 2) {
                setModalImport({...modalImport, error: "File tải lên không thể lớn hơn 2MB"});
                return false;
            } else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if (fileData) {
                    setFileName(fileData.file);
                }
                loading.stopRunLoading();
            }
        } else {
            setFileName("");
        }
    };
    const handleModalImportCancel = () => {
        clearStateModalImport()
        setFileName("")
    }
    const confirmImport = async () => {
        if (fileName.trim() === "") {
            setModalImport({...modalImport, error: "Bạn chưa tải file ảnh lên!"});
            return false;
        }
        const data = {
            secret: secret,
            FileDataSigned: fileName,
            number_signer_file_revoke: 1,
            typeSignRevoke: 2  // 2: upload File |else: signed by token
        }
        updateSignedContract(data).then()
    };
    const clearStateModalImport = () => {
        setModalImport({...initialStateModalImport})
    }

    const updateSignedContract = async (data) => {
        const result = await SignRevokeFileCompensationServices.updateFileRevokeSigned(data);
        if (result && result.status === 200) {
            onSuccessAction("Ký thành công!", () => {
                window.location.reload();
            });
        } else {
            onFailAction("Có lỗi xảy ra !");
        }
    };

    /**
     * Action Btn
     */
    const importClick = async () => {
        setModalImport({...modalImport, visible_modal: true});
    };
    const onSignClick = async () => {
        let cert = await getCertificate();
        if (!cert.data) {
            onFailAction("Không tìm thấy chứng thư số");
            return;
        }
        const time = await SignRevokeFileCompensationServices.getTimeServer();
        let allDataSign = {...dataSign, CertSerial: cert.data, FileData: fileSign, signDate: time};
        onSign(allDataSign);
    };
    const onDenyClick = async () => {
        const dataSend = {secret: secret};
        const deny = await SignRevokeFileCompensationServices.denySignRevoke(dataSend);
        if (deny.status === 200) {
            onSuccessAction(deny.message);
            window.location.reload();
        } else {
            onFailAction(deny.message);
        }
    };

    const getCertificate = async () => {
        loadingHelper.runLoadingBlockUI();
        const dataSend = {secret: secret};
        const result = await SignRevokeFileCompensationServices.getCertificate(dataSend);
        loadingHelper.stopRunLoading();
        return result;
    };
    const onSign = (allDataSign) => {
        axios.post(`http://localhost:6888/api/sign/signpdf`, allDataSign)
            .then((response) => {
                const data = response.data;
                console.log("dataSigned", data)
                if (!data.FileDataSigned) {
                    onFailAction('Có lỗi xảy ra trong quá trình ký!');
                    return;
                }
                updateSignedContract({...data, secret: secret, typeSignRevoke: 1, number_signer_file_revoke: 1}).then();
            })
            .catch((error) => {
                onFailAction(error);
            });
    };

    return (
        <div>
            <nav className="navbar navbar-light bg-light">
                <div className="row">
                    <div className="col-md-5">
                        <a className="navbar-brand" href="/#">
                            <img src={"/images/logo2.jpg"} width="20%" alt="logo"/>
                        </a>
                    </div>
                </div>
            </nav>
            <div className="container mt-5">
                <div className="card">
                    <div className="card-header text-center text-uppercase">
                        <h5>Đề nghị thu hồi chứng thư số cấp bù (TH-01)</h5>
                    </div>
                    <div className="card-body">
                        <div className="col-md-12 text-center mt-3">
                            <Form>
                                <ModalEnterPin
                                    title={'Nhập mã xác thực'}
                                    visible={modalPin.visible_modal_password}
                                    error={modalPin.error_password}
                                    value={modalPin.password}
                                    onChange={onChangeDataPin}
                                    handleCancel={handleModalConnectCancel}
                                    handleDeny={confirmPin}

                                />
                                <ModalUploadFile
                                    title={'Tải file ký thu hồi'}
                                    label="Tải file ký thu hồi (Chỉ chấp nhận file ảnh và pdf)"
                                    visible={modalImport.visible_modal}
                                    error={modalImport.error}
                                    filename={fileName}
                                    extensionsAllow={['pdf', 'PDF', 'png', 'PNG', 'jpg', 'jpeg', 'JPG', 'JPEG']}
                                    accept={".pdf,.png,.jpg,.jpeg"}
                                    onchangeFileUpload={onChangeFile}
                                    handleCancel={handleModalImportCancel}
                                    handleImport={confirmImport}
                                />

                                {confirm ? (
                                    <iframe
                                        title="Biên bản thanh lý"
                                        src={`${file}`}
                                        height="900px"
                                        width="90%"
                                    />
                                ) : ("")}

                            </Form>
                        </div>

                        {!confirm ? (
                            <div className="text-center">
                                <button className="btn btn-success" onClick={submitPin}><i
                                    className="fas fa-file-signature"/>Xác thực
                                </button>
                            </div>

                        ) : ("")}

                        {canSign ? (
                            <React.Fragment>
                                <div className="input-group d-flex justify-content-center p-5">
                                    <div className="text-center">
                                        <button className="btn btn-primary mr-3" onClick={importClick}><i
                                            className="fas fa-file-signature"/> upLoad file
                                        </button>
                                    </div>

                                    <div className="text-center">
                                        <button className="btn btn-success mr-3" onClick={onSignClick}><i
                                            className="fas fa-file-signature"/> Ký file xác nhận
                                        </button>
                                    </div>

                                    <div className="text-center">
                                        <button className="btn btn-danger" onClick={onDenyClick}><i
                                            className="fas fa-file-signature"/>Từ chối ký
                                        </button>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignRevokeCompensationPage;