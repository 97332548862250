import BaseServices from "../base/BaseServices";

const getFileCustomerSign = async (data) => {
    return await BaseServices.request(
        `sign-compensation/get-file-customer-sign`,
        data,
        "POST"
    );
};

const denySignRevoke = async (data) => {
    return await BaseServices.request(
        `sign-compensation/deny-sign-revoke`,
        data,
        "POST"
    );
};

const getCertificate = async (data) => {
    return await BaseServices.request(
        `sign-compensation/get-certificate-sign`,
        data,
        "POST"
    );
};

const updateFileRevokeSigned = async (data) => {
    return await BaseServices.request(
        `sign-compensation/update-signed-revoke`,
        data,
        "POST"
    );
};

const getTimeServer = async () => {
    return await BaseServices.request(
        `sign-compensation/get-time-server-sign`,
        {},
        "GET"
    );
};

export const SignRevokeFileCompensationServices = {
    getFileCustomerSign,
    denySignRevoke,
    getCertificate,
    updateFileRevokeSigned,
    getTimeServer
};
