import React, {} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import {onFailAction} from "helpers/SwalCommon";
import {RouteComponentProps} from "react-router";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import {
    STATUS_REQUEST,
} from "../../helpers/enum/request-token/RequestTokenEnums";
import WrappedGenCertSearchForm from "./search/InfoCertSearchForm";
import moment from "moment";
import { Tag } from "antd";
import {InfoCertificateServices} from "../../services/info-certificate/InfoCertificateServices";
import {STATUS_CERT_LABEL} from "../customer/Enum";

interface Props extends RouteComponentProps {
    history?:any;
    location?:any;
    match?:any;
}

export const InfoCertList: React.FC<Props> = props => {

    const renderActionButton = (text, record, index) => {
        return (
            <TableActionButton
                onClickPreviewButton={() =>
                    props.history.push(`info-certificate/xem/${record.id}`)
                }
            />
        );
    };

    const renderStatusColumn = (text, record, index) => {
        return (
            <Tag color={`${STATUS_CERT_LABEL[text].class}`}>
        {STATUS_CERT_LABEL[text].label}
      </Tag>
        );
    };
    const renderStatusRequest = (text, record, index) => {
        return (
            <Tag color={`${STATUS_REQUEST[text].class}`}>
        {STATUS_REQUEST[text].label}
      </Tag>
        );
    };
    const formatDateTime = (date: string) => {
        if (date) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return "";
        }
    };

    const columns = [
        {
            title: 'Mã định danh',
            dataIndex: '',
            render: (e) => {
                if (e) {
                    if(Number(e.requestcertificate.object) === 1 || Number(e.requestcertificate.object) === 2 || Number(e.requestcertificate.object) === 3){
                        if(Number(e.customer_type) === 1){
                            return <p>{e.requestcertificate.organization.code}</p>;
                        }else {
                            return <p>{e.requestcertificate.requestpersonal.passport}</p>;
                        }

                    }else{
                        return <p>{e.request_change_info.code}</p>;
                    }
                }
            }
        },
        { title: "Mã bảo mật", dataIndex: '',
            render: (e) => {
                if(Number(e.object) === 1 || Number(e.object) === 2 || Number(e.object) === 3){
                    return <p>{e.requestcertificate.secret_code}</p>;
                }else {
                    if(Number(e.customer_type) === 1){
                        return <p>{e.request_change_info.cert_change_organ.secret_code}</p>;
                    }else {
                        return <p>{e.request_change_info.cert_change_per.secret_code}</p>;
                    }
                }
            }
        },
        {
            title: 'Đối tượng',
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if(Number(e.object) === 1){
                        return <p>Cấp mới</p>;
                    }else if(Number(e.object) === 2){
                        return <p>Gia hạn</p>;
                    }else if(Number(e.object) === 3){
                        return <p>Thay đổi thông tin</p>;
                    }else {
                        return <p>Chuyển đổi</p>;
                    }
                }
            }
        },
        {
            title: "Gói dịch vụ",
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if(Number(e.object) === 1 || Number(e.object) === 2 || Number(e.object) === 3){
                        return <p>{e.requestcertificate.cateservicespackage.name}</p>;
                    }else {
                        if(Number(e.customer_type) === 1){
                            return <p>{e.request_change_info.cert_change_organ.request.cateservicespackage.name}</p>;
                        }else {
                            return <p>{e.request_change_info.cert_change_per.request.cateservicespackage.name}</p>;
                        }
                    }
                }
            }
        },
        {
            title: "Thời gian tạo",
            dataIndex: "created_at",
            render: (text, record, index) => formatDateTime(text)
        },
        {
            title: "Loại yêu cầu",
            dataIndex: "object", render: renderStatusRequest
        },
        {
            title: "Trạng thái",
            dataIndex: "status", render: renderStatusColumn
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        }
    ];
    const fetch = async (params = {}) => {
        try {
            const data = await InfoCertificateServices.getListIndex(params);
            return data;
        } catch (error) {
            onFailAction(error.message);
        }
    };

    return (
        <PageWrapper title="Danh sách chứng thư số">
            <WrappedGenCertSearchForm/>
            <Table columns={columns} onFetchData={fetch}/>
        </PageWrapper>
    );
};
