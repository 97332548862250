import BaseServices from "../base/BaseServices";

const getFileDk02 = async (id, type, password, codeUi, captcha) => {
    const result = await BaseServices.request(
        `getFileDk02/${id}/${type}/${password}/${codeUi}/${captcha}`,
        {},
        "GET"
    );
    return result;
};

const getCertificate = async (id, type) => {
    const result = await BaseServices.request(
        `getCertificateReSign/${id}/${type}`,
        {},
        "GET"
    );
    return result;
};

const updateFileSigned = async (data) => {
    const result = await BaseServices.request(
        `updateSignedContractReSign`,
        data,
        "POST"
    );
    return result;
};
const getTimeServer = async () => {
    const result = await BaseServices.request(
        `get-time-server-sign`,
        {},
        "GET"
    );
    return result;
};
const getCertInToken = async (dataSend) =>{
    const result = await BaseServices.requestGenCert(
        "getcert",
        dataSend,
        "GET"
    );
    return result;
};
const getCaptcha = async (codeUi) => {
    const result = await BaseServices.request(
        `get-captcha/${codeUi}`,
        {},
        "GET"
    );
    return result;
};

export const ReSignDk02Services = {
    getFileDk02,
    getCertificate,
    updateFileSigned,
    getTimeServer,
    getCertInToken,
    getCaptcha
};
