import React, {useEffect, useState} from "react";
import { Form } from "antd";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import { FormComponentProps } from "antd/lib/form";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";
import {UserServices} from "../../../services/user/UserServies";
import SelectDateSearch from "../../../components/common/form/input-with-label/SelectDateSearch";

interface Props extends FormComponentProps {}

const RequestTokenSearchForm: React.FC<Props> = props => {
    const [userType, setUserType] = useState(0);
    const [userList, setUserList] = useState({});
    const getListUser = async () => {

        const userService = new UserServices();
        const user = await userService.getUserByType('5,7,8');

        setUserList(user.data)
    };

    const fetchUserCreated = async  () => {
        const user = new UserServices();
        const userInfo = await user.getUserAuth();

        const type = userInfo.data.type;
        setUserType(type);
    };
    useEffect(() => {
        fetchUserCreated();
        getListUser();
        // eslint-disable-next-line
    }, []);
    const TYPE_BUSINESS_SUPPORT = 6;
    return (
        <Form>
            <div className="input-group">
                {userType === TYPE_BUSINESS_SUPPORT ?(
                <SelectWithLabel
                    options={userList}
                    name="user_id"
                    wrappedClass="col-md-2"
                    form={props.form}
                    label={""}
                    isRequired={false}
                    placeholder={"Người xử lý"}
                />
                ) : (
                    ""
                )}
                <SelectWithLabel
                    options={{1: "Điều chỉnh", 2: "Gia hạn", 3: "Tạm dừng", 4: "Thu hồi"}}
                    name="type"
                    wrappedClass="col-md-2 nopadding-left"
                    form={props.form}
                    placeholder="Loại yêu cầu"
                />
                <SelectWithLabel
                    options={{2:"Từ chối xử lý",3:"Chờ hỗ trợ", 4: "Hoàn thành" }}
                    name="status"
                    wrappedClass="col-md-2 nopadding-left"
                    form={props.form}
                    placeholder="Chọn trạng thái"
                />
                <SelectDateSearch
                    name="createdAt"
                    form={props.form}
                    wrapClass="col-md-2 nopadding-left"
                    label=""
                    placeholder="Ngày tiếp nhận"
                />
                <div className="form-group col-md-4 mt-1 nopadding-left">
                    <ButtonSearch data={props.form.getFieldsValue()} />
                </div>
            </div>
        </Form>
    );
};

const WrappedRequestSupportSearchForm = Form.create<Props>({
    name: "RequestTokenSearchForm"
})(RequestTokenSearchForm);

export default WrappedRequestSupportSearchForm;
