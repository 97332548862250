import React, {Component} from "react";
import {Layout} from "antd";
import SideMenu from "./side-menu/SideMenu";
import RightPageSide from "./right-page-side/RightPageSide";
import Login from "../../pages/login/Login";
import {Route, Switch, withRouter} from "react-router";
import SignPage from "../../pages/sign-coop-stop/index";
import SignRevokeFilePage from "../../pages/sign-file-revoke-destroy-pause";
import ViewFileDk02Page from "../../pages/view-file-dk02";
import SignDK02HSMPage from "../../pages/sign-dk02-hsm/signDK02HSM";
import SignRevokeCompensationPage from "../../pages/sign-file-compensation";

interface Props {}
interface State {}

export default class MainPage extends Component<Props, State> {
  state = {};

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Switch>
          <Route exact path="/ky-hop-dong-dung-hop-tac/:id" component={SignPage}/>
            <Route exact path="/customer-sign/:secret" component={SignRevokeFilePage}/>
            <Route exact path="/customer-sign-compensation/:secret" component={SignRevokeCompensationPage}/>
            <Route exact path="/customer-sign-hsm/:secret" component={SignDK02HSMPage}/>
            <Route exact path="/view-info-dk02/:secret/:type" component={ViewFileDk02Page}/>
          <Route exact path="/login" component={LoginContainer} />
          <Route component={DefaultContainer}/>
        </Switch>
      </Layout>
    );
  }
}

const LoginContainer = () => (
  <Route path="/login" component={props => <Login {...props}/>} />
);

const SideMenuWithRouter = withRouter(props => <SideMenu {...props} />);

const DefaultContainer = () => (
    <React.Fragment>
        <SideMenuWithRouter />
        <RightPageSide/>
    </React.Fragment>
);
