export const STATUS_CERT_LABEL = {
    1:{
        label: "Hoạt động",
        class: "green"
    },
    2:{
        label: "Đã thu hồi",
        class: "orange"
    },
    3:{
        label: "Đã hủy",
        class: "purple"
    },
    4:{
        label: "Đã tạm dừng",
        class: "volcano"
    },

    8:{
        label: "Đã xóa",
        class: "red"
    },
    9: {
        label: "Đã hết hạn",
        class: "magenta"
    },
    10: {
        label: "Chờ khách hàng ký xác nhận thu hồi",
        class: "crimson"
    },
    11: {
        label: "Chờ nghiệp vụ duyệt thu hồi",
        class: "fuchsia"
    },
    12: {
        label: "Nghiệp vụ từ chối thu hồi",
        class: "coral"
    },
    15: {
        label: "Chờ khách hàng ký xác nhận hủy",
        class: "pea"
    },
    16: {
        label: "Chờ nghiệp vụ duyệt hủy",
        class: "olive"
    },
    17: {
        label: "Nghiệp vụ từ chối hủy",
        class: "lime"
    },
    20: {
        label: "Chờ khách hàng ký xác nhận tạm dừng",
        class: "cyan"
    },
    21: {
        label: "Chờ nghiệp vụ duyệt tạm dừng",
        class: "azure"
    },
    22: {
        label: "Nghiệp vụ từ chối tạm dừng",
        class: "royal blue"
    },
  };

  export const FILE_TYPE_OPTIONS = {
    1: "Ký điện tử",
    2: "Ký tươi"
  };