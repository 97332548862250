import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-ho-tro/";

const getListIndex = async (data: object) => {
    const requestTokenCTS = await BaseServices.request(
        PREFIX_SERVICES + "index",
        data,
        "GET"
    );
    return requestTokenCTS;
};

const approveRequest = async id => {
    const requestTokenCTS = await BaseServices.request(
        PREFIX_SERVICES + "approveRequest/" + id,
        {},
        "GET"
    );
    return requestTokenCTS;
};

const denyRequest = async (id,reason) => {
    const requestTokenCTS = await BaseServices.request(
        PREFIX_SERVICES + "denyRequest/" + id + "/"+reason,
        {},
        "GET"
    );
    return requestTokenCTS;
};

export const RequestSupportServices = {
    getListIndex,
    approveRequest,
    denyRequest,
};
