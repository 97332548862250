import React, { Component } from "react";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import { STATUS_OPTIONS } from "../enum/AgencyEnum";
import { queryStringToJSON } from "../../../helpers/NewCaCrmHelper";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";
import ButtonCreate from "../../../components/common/form/button/ButtonCreate";
import { History } from "history";
import { match } from "react-router";
import { AgencyService } from "../../../services/agency/AgencyServices";
import { AgencyPermission } from "../../../helpers/enum/PermissionEnums";
import InputWithoutLabel from "../../../components/common/form/input-with-label/input/InputWithoutLabel";

interface Props extends FormComponentProps {
  searchValue: any;
  history: History;
  match: match;
}
interface State {}

class AgencySearchForm extends Component<Props, State> {
  state = {
    managers: {}
  };

  componentDidMount() {
    this.getListManager();
    const { form, searchValue } = this.props;
    if (searchValue) {
      const searchObject = queryStringToJSON(searchValue);
      form.setFieldsValue(searchObject);
    }
  }

  async getListManager() {
    try {
      const agencyServices = new AgencyService();
      const user = await agencyServices.getManagerByAuth();
      this.setState({
        managers: user.data
      });
    } catch (error) {}
  }

  render() {
    return (
      <Form>
        <div className="input-group nopadding-left">
          <SelectWithLabel
            options={this.state.managers}
            name="manager"
            wrappedClass="col-md-2 nopadding-left"
            form={this.props.form}
            placeholder="Chọn người quản lý"
          />
          <SelectWithLabel
            options={STATUS_OPTIONS}
            name="status"
            wrappedClass="col-md-2 nopadding-left"
            form={this.props.form}
            placeholder="Chọn trạng thái"
          />
          <div className="form-group col-md-2 nopadding-left">
            <InputWithoutLabel form={this.props.form} name="name" placeholder="Mã, tên đại lý" />
          </div>
          <div className="form-group col-md-2 nopadding-left">
            <InputWithoutLabel form={this.props.form} name="tax-code" placeholder="Mã số thuế" />
          </div>
          <div className="form-group col-md-4 mt-1 nopadding-left">
              <ButtonSearch data={this.props.form.getFieldsValue()} />
              <ButtonCreate
                  permission={AgencyPermission.CREATE}
                  toUrl="/quan-ly-dai-ly/them-moi"
              />
          </div>
        </div>
      </Form>
    );
  }
}

const WrappedAgencySearchForm = Form.create<Props>({ name: "AgencySearch" })(
  AgencySearchForm
);

export default WrappedAgencySearchForm;
