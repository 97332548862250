import React  from "react";
import { Form } from "antd";
import AgencyInfoRow from "../../form/create/input-row/AgencyInfoRow";
import { WrappedFormUtils } from "antd/lib/form/Form";
import DeputyInfoRow from "../../form/create/input-row/DeputyInfoRow";
import OfficeInfoRow from "../../form/create/input-row/OfficeInfoRow";
import LeadershipInfoRow from "../../form/create/input-row/LeadershipInfoRow";
import ContractInfoRow from "../../form/create/input-row/ContractInfoRow";
import AgencyPreviewButtonGroup from "../button-group/AgencyPreviewButtonGroup";
import { onFailAction } from "../../../../helpers/SwalCommon";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface Props {
  form: WrappedFormUtils;
  agencyModel: any;
}

const AgencyApproveForm: React.FC<Props> = props => {
  const onClickWatchFile = () =>{
        try {
              let token = localStorage.getItem("currentUser");
              if (token){
                  let obj = JSON.parse(token);
                  let link = `${REACT_APP_BASE_API_URL}qlbh/agency/get-file-gpkd/${props.agencyModel.id}?token=${obj.token}`;
                  window.open(link)
              }
          } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file");
        }
  };
  return (
    <Form>
      <AgencyInfoRow disable={true} form={props.form} />
      <DeputyInfoRow disable={true} form={props.form} />
      <OfficeInfoRow disable={true} form={props.form} />
      <LeadershipInfoRow disable={true} form={props.form} />
      <ContractInfoRow
        disable={true}
        form={props.form}
        file_gpkd={props.agencyModel.file_g_p_k_d}
        onClickDownloadFile={onClickWatchFile}
      />
      <AgencyPreviewButtonGroup id={props.agencyModel.id} form={props.form} />
    </Form>
  );
};

export default AgencyApproveForm;
