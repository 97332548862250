import BaseServices from "../base/BaseServices";

const getFileCustomerSign = async (data) => {
    const result = await BaseServices.request(
        `sign-hsm/get-file-customer-sign`,
        data,
        "POST"
    );
    return result;
};

const signAction = async (data) => {
    const result = await BaseServices.request(
        `sign-hsm/sign-action`,
        data,
        "POST"
    );
    return result;
};

export const SignDK02HSMServices = {
    getFileCustomerSign,
    signAction
};
