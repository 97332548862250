import React, { Component } from "react";
import { Layout } from "antd";
import "./css/util.css";
import "./css/login.css";
import "./css/main.css";
import LoginForm from "./form/LoginForm";
import { authenticationService } from "../../services/authentication/AuthenticationService";
import { History } from "history";
import { connect } from "react-redux";
import { setAuthDATA } from "actions/authAction";

const { Content } = Layout;

interface Props {
  history: History;
}

interface reduxProps {
  setUserData: (userData) => {};
}

type combineProps = reduxProps & Props;

interface State {}

class Login extends Component<combineProps, State> {
  constructor(props) {
    super(props);
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }
  }

  state = {};

  render() {
    return (
      <Content className="background-login-page">
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100 p-l-85 p-r-85 p-t-55 p-b-55">
              <LoginForm
                setUser={this.props.setUserData}
                history={this.props.history}
              />
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { authReducer } = state;
  return { userData: authReducer };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: userData => {
      dispatch(setAuthDATA(userData));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
